export const olifeQuestions = [
  "When in the dark do you often see shapes and forms even though there is nothing there?",
  "Are your thoughts sometimes so strong that you can almost hear them?",
  "Have you ever thought that you had special, almost magical powers?",
  "Have you sometimes sensed an evil presence around you, even though you could not see it?",
  "Do you think that you could learn to read other's minds if you wanted to?",
  "When you look in the mirror does your face sometimes seem quite different from usual?",
  "Do ideas and insights sometimes come to you so fast that you cannot express them all?",
  "Can some people make you aware of them just by thinking about you?",
  "Does a passing thought ever seem so real it frightens you?",
  "Do you feel that your accidents are caused by mysterious forces?",
  "Do you ever have a sense of vague danger or sudden dread for reasons that you do not understand?",
  "Does your sense of smell sometimes become unusually strong?",
  "Are you easily confused if too much happens at the same time?",
  "Do you frequently have difficulty in starting to do things?",
  "Are you a person whose mood goes up and down easily?",
  "Do you dread going into a room by yourself where other people have already gathered and are talking?",
  "Do you find it difficult to keep interested in the same thing for a long time?",
  "Do you often have difficulties in controlling your thoughts?",
  "Are you easily distracted from work by daydreams?",
  "Do you ever feel that your speech is difficult to understand because the words are all mixed up and don't make sense?",
  "Are you easily distracted when you read or talk to someone?",
  "Is it hard for you to make decisions?",
  "When in a crowded room, do you often have difficulty in following a conversation?",
  "Are there very few things that you have ever enjoyed doing?",
  "Are you much too independent to get involved with other people?",
  "Do you love having your back massaged?",
  "Do you find the bright lights of a city exciting to look at?",
  "Do you feel very close to your friends?",
  "Has dancing or the idea of it always seemed dull to you?",
  "Do you like mixing with people?",
  "Is trying new foods something you have always enjoyed?",
  "Have you often felt uncomfortable when your friends touch you?",
  "Do you prefer watching television to going out with people?",
  "Do you consider yourself to be pretty much an average sort of person?",
  "Would you like other people to be afraid of you?",
  "Do you often feel the impulse to spend money which you know you can't afford?",
  "Are you usually in an average kind of mood, not too high and not too low?",
  "Do you at times have an urge to do something harmful or shocking?",
  "Do you stop to think things over before doing anything?",
  "Do you often overindulge in alcohol or food?",
  "Do you ever have the urge to break or smash things?",
  "Have you ever felt the urge to injure yourself?",
  "Do you often feel like doing the opposite of what other people suggest even though you know they are right?",
];
